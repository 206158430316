:root {
  --main-color: #7fb522;
  --secondary-color: #1c6c9b;
}

.main-layout {
  max-width: 60%;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

.header {
  background-color: var(--main-color);
  height: 80px;
}

.header__logo {
  height: 80%;
}

.header__menu {
  display: block;
  background-color: var(--main-color);
  user-select: none;
  font-size: 18px;
  border: none;

  .ant-menu-item,
  a {
    color: #ffffff !important;
    border: none !important;
  }

  .ant-menu-submenu-title:hover,
  .ant-menu-item:hover,
  .ant-menu-item-selected,
  .ant-menu-item-active,
  a:hover {
    text-decoration: underline;
  }
}

.header__drawer,
.header__drawer-btn {
  display: none;
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 60%;
  margin: 0 auto;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .header__inner {
    max-width: 100%;
  }

  .header__mobile-menu {
    border-right: none;
  }

  .header__logo {
    margin: 0 auto;
  }

  .header__menu {
    display: none;
  }

  .header__drawer,
  .header__drawer-btn {
    display: block;
  }

  .header__drawer-btn {
    position: absolute;
    right: 10px;
  }

  .main-layout {
    max-width: 95%;
  }

  .ant-layout-header {
    padding: 0 10px;
  }
}
