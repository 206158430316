.login {
  width: 40%;
  margin: 0 auto;
  .ant-input,
  .ant-input-password {
    border-color: var(--main-color);
  }
}

.login__title {
  font-size: x-large;
  text-align: center;
  margin-bottom: 10px;
}

.login__btn {
  width: 100%;
  font-size: x-large;
  height: auto;
}

.login__slogan {
  font-size: x-large;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .login {
    width: 90%;
  }
}

