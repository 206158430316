.billing__error-modal--header,
.billing__success-modal--header {
  background-color: var(--error-color);
  margin-bottom: 50px;
  padding: 24px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;

  > img {
    margin: 0 auto;
    margin-bottom: 50px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    font-size: x-large;
    color: #ffffff;
  }
}

.billing__error-modal--header {
  background-color: var(--error-color);
}

.billing__success-modal--header {
  background-color: var(--success-color);
}

.billing__error-modal--info,
.billing__error-modal--contact,
.billing__error-modal--logos,
.billing__success-modal--info,
.billing__success-modal--contact,
.billing__success-modal--logos {
  text-align: center;
  margin-bottom: 50px;
}

.billing__success-modal--support {
  text-align: center;
}

.billing__error-modal--logos,
.billing__success-modal--logos {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    flex: 1;
    width: 45%;
  }
}

.billing__error-modal--info,
.billing__success-modal--info {
  > span {
    height: 50px;
    width: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 32px;
    margin-bottom: 10px;
  }
}

.billing__error-modal--info {
  > span {
    background-color: var(--error-color);
  }
}

.billing__success-modal--info {
  > span {
    background-color: var(--success-color);
  }
}
