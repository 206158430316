:root {
  --success-color: #7fb522;
  --error-color: #ff4d4f;
}

.billing {
  padding-bottom: 50px;
}

.billing__table {
  margin-bottom: 30px;

  .ant-table-footer {
    text-align: right;
  }
}

.billing__form {
  label {
    text-transform: uppercase;
    border-radius: 8px;
  }
  .ant-picker {
    width: 100%;
  }
}

.billing__form--title {
  font-size: x-large;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.billing__btn {
  width: 100%;
  font-size: x-large;
  height: auto;
}

.billing__form--select-method {
  display: flex;
  flex-direction: column;
  > * {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-bottom: 10px;
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    position: relative;
    .anticon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;
    }
  }

  > :last-child {
    margin-bottom: 24px;
  }
}

.billing__form--select-bank-types {
  > * {
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }
}
