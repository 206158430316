@font-face {
  font-family: "Cambria";
  src: url(./assets/fonts/Cambria.ttf) format("truetype");
}

html,
body {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.ant-input,
.ant-input-password,
.ant-btn,
.ant-select-selector {
  border-radius: 8px !important;
}

.ant-select,
.ant-select-item {
  text-transform: uppercase;
}
