.features {
  display: flex;
  flex-direction: column;
  .ant-btn:not(.ant-btn-primary[disabled]) {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
  }

  .ant-select:not(.ant-select-disabled) .ant-select-selector {
    border-color: var(--main-color);
  }
  .ant-radio-inner::after {
    background-color: var(--secondary-color);
  }

  .ant-radio-checked::after,
  .ant-radio-checked .ant-radio-inner,
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--secondary-color);
  }

  .features__divider {
    height: 1px;
    background-color: var(--main-color);
    margin: 30px auto;
    width: 70%;
  }

  .features__pricing {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .features__pricing--option {
      display: flex;
      flex-direction: column;
    }

    .ant-radio-wrapper {
      > span {
        display: inline-block;
      }
      .ant-radio {
        margin-top: 3px;
      }
    }
  }

  .features__update-btn {
    margin: 10px auto 0 auto;
  }
}

.features__collapse {
  .ant-collapse-item:not(.ant-collapse-item-active) {
    .ant-collapse-header {
      background-color: var(--main-color);
      color: white;
    }

    .ant-collapse-header:hover {
      color: rgba(0, 0, 0, 0.85);
      background-color: #D9D9D9;
    }
  }
}

.features__intro {
  text-align: center;
  font-size: large;
  margin-bottom: 10px;
}
